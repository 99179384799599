// Initialize first
@import '../init';

// Style Here
.checkout {
  .hero {
    .container {
      padding-top: toRem(166);
      padding-bottom: toRem(80);
      display: flex;
      flex-direction: column;
      gap: toRem(64);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
        padding-top: toRem(136);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(48);
      }
    }

    &__header {
      max-width: 514px;
      display: flex;
      flex-direction: column;
      gap: toRem(24);
      text-align: center;
      align-items: center;

      h1 {
        color: $color-white;
      }

      p {
        color: $color-lighter-grey;
      }
    }

    &__content {
      background-color: $color-white;
      box-shadow: $shadow-lg;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      gap: 0;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      & > * {
        width: 50%;
        padding: toRem(48) toRem(64);

        @include media-breakpoint-down(lg) {
          padding: toRem(28) toRem(20);
        }

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      //   Element used in content
      .content-para {
        display: flex;
        flex-direction: column;
        gap: toRem(24);

        h3 {
          color: $color-black;
        }
      }

      .content-left {
        display: flex;
        flex-direction: column;
        gap: toRem(32);
        align-items: flex-start;

        .content-feature {
          color: $color-black;
          display: flex;
          flex-direction: column;
          gap: toRem(24);

          .feature-list {
            display: flex;
            flex-direction: column;
            gap: toRem(16);

            & > * {
              display: flex;
              flex-direction: row;
              gap: toRem(8);
              align-items: center;
            }
          }
        }
      }

      .content-right {
        background-color: rgba($color: $color-extra-light-deep-blue, $alpha: 0.3);
        display: flex;
        flex-direction: column;
        gap: toRem(48);

        .content-form-wrap {
          display: flex;
          flex-direction: column;
          gap: toRem(32);

          //   Form
          .content-form {
            display: flex;
            flex-direction: column;
            gap: toRem(20);

            .items__inputs {
              background-color: $color-white;
            }

            // Select Form Custom
            .form-group__plan {
              .items__inputs {
                justify-content: space-between;
                flex-direction: row-reverse;

                .items__inputs--input {
                  padding: 0;
                  color: $color-black;

                  &:focus {
                    border: unset;
                    box-shadow: unset;
                  }

                  & > * {
                    width: 200px;
                  }
                }
              }
            }
          }

          //   Price Count
          .total-count {
            display: flex;
            flex-direction: column;
            gap: toRem(16);

            .total-line {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              &--black {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }
}
